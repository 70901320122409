import { createSlice } from "@reduxjs/toolkit";
import { loadLayoutData } from "./operations";

export type MenuItemType = { link: string; title: string };

type SliceState = {
  loading: boolean;
  error: null | string;
  solutions: MenuItemType[];
};

const initialState: SliceState = {
  error: null,
  loading: false,
  solutions: [],
};

const navigationSlice = createSlice({
  name: "navigationData",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadLayoutData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadLayoutData.fulfilled, (state, { payload }) => {
        state.solutions = payload.navigation.solutions.map((solution) => ({
          link: `/erps-apps/${solution.attributes.slug}`,
          title: solution.attributes.name,
        }));
        state.loading = false;
      })
      .addCase(loadLayoutData.rejected, (state, { payload }) => {
        if (payload) {
          state.error = payload;
        }
        state.loading = false;
      });
  },
});

export const { reducer, actions } = navigationSlice;
