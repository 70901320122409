import { configureStore } from '@reduxjs/toolkit';
import { reducer as contactInfoReducer } from './contactInfoSlice';
import { reducer as navigationReducer } from './navigationSlice';


const createStore = () => configureStore({
  reducer: {
    contactInfo: contactInfoReducer,
    navigationData: navigationReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),

  devTools: process.env.NODE_ENV !== 'production',
});


type ConfiguredStore = ReturnType<typeof createStore>
type StoreGetState = ConfiguredStore["getState"]
export type RootState = ReturnType<StoreGetState>
export type AppDispatch = ConfiguredStore["dispatch"]

export default createStore