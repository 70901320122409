exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agb-tsx": () => import("./../../../src/pages/agb.tsx" /* webpackChunkName: "component---src-pages-agb-tsx" */),
  "component---src-pages-contact-career-[id]-tsx": () => import("./../../../src/pages/contact/career/[id].tsx" /* webpackChunkName: "component---src-pages-contact-career-[id]-tsx" */),
  "component---src-pages-contact-career-index-tsx": () => import("./../../../src/pages/contact/career/index.tsx" /* webpackChunkName: "component---src-pages-contact-career-index-tsx" */),
  "component---src-pages-contact-contact-form-tsx": () => import("./../../../src/pages/contact/contact-form.tsx" /* webpackChunkName: "component---src-pages-contact-contact-form-tsx" */),
  "component---src-pages-contact-jetzt-bewerben-tsx": () => import("./../../../src/pages/contact/jetzt-bewerben.tsx" /* webpackChunkName: "component---src-pages-contact-jetzt-bewerben-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-erps-apps-[id]-tsx": () => import("./../../../src/pages/erps-apps/[id].tsx" /* webpackChunkName: "component---src-pages-erps-apps-[id]-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-[id]-tsx": () => import("./../../../src/pages/news/[id].tsx" /* webpackChunkName: "component---src-pages-news-[id]-tsx" */),
  "component---src-pages-news-categories-[id]-tsx": () => import("./../../../src/pages/news/categories/[id].tsx" /* webpackChunkName: "component---src-pages-news-categories-[id]-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-news-search-tsx": () => import("./../../../src/pages/news/search.tsx" /* webpackChunkName: "component---src-pages-news-search-tsx" */),
  "component---src-pages-support-remote-maintenance-tsx": () => import("./../../../src/pages/support/remote-maintenance.tsx" /* webpackChunkName: "component---src-pages-support-remote-maintenance-tsx" */),
  "component---src-pages-tbiz-edi-automation-tsx": () => import("./../../../src/pages/tbiz-edi/automation.tsx" /* webpackChunkName: "component---src-pages-tbiz-edi-automation-tsx" */),
  "component---src-pages-tbiz-edi-b-2-b-procurement-tsx": () => import("./../../../src/pages/tbiz-edi/b2b-procurement.tsx" /* webpackChunkName: "component---src-pages-tbiz-edi-b-2-b-procurement-tsx" */),
  "component---src-pages-tbiz-edi-base-data-tsx": () => import("./../../../src/pages/tbiz-edi/base-data.tsx" /* webpackChunkName: "component---src-pages-tbiz-edi-base-data-tsx" */),
  "component---src-pages-tbiz-edi-dashboard-tsx": () => import("./../../../src/pages/tbiz-edi/dashboard.tsx" /* webpackChunkName: "component---src-pages-tbiz-edi-dashboard-tsx" */),
  "component---src-pages-tbiz-edi-edi-tsx": () => import("./../../../src/pages/tbiz-edi/edi.tsx" /* webpackChunkName: "component---src-pages-tbiz-edi-edi-tsx" */),
  "component---src-pages-tbiz-edi-gateway-tsx": () => import("./../../../src/pages/tbiz-edi/gateway.tsx" /* webpackChunkName: "component---src-pages-tbiz-edi-gateway-tsx" */),
  "component---src-pages-tbiz-edi-monitoring-tsx": () => import("./../../../src/pages/tbiz-edi/monitoring.tsx" /* webpackChunkName: "component---src-pages-tbiz-edi-monitoring-tsx" */),
  "component---src-pages-tbiz-edi-screen-display-tsx": () => import("./../../../src/pages/tbiz-edi/screen-display.tsx" /* webpackChunkName: "component---src-pages-tbiz-edi-screen-display-tsx" */),
  "component---src-pages-tbiz-edi-tbiz-shops-tsx": () => import("./../../../src/pages/tbiz-edi/tbiz-shops.tsx" /* webpackChunkName: "component---src-pages-tbiz-edi-tbiz-shops-tsx" */),
  "component---src-pages-uber-uns-tsx": () => import("./../../../src/pages/uber-uns.tsx" /* webpackChunkName: "component---src-pages-uber-uns-tsx" */)
}

