import React, { ReactNode } from "react";
import { Provider } from "react-redux";
import createStore from "./store";

type Props = {
  element: ReactNode;
};

export default ({ element }: Props) => {
  const store = createStore();

  return <Provider store={store}>{element}</Provider>;
};
