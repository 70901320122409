import { createSlice } from '@reduxjs/toolkit';
import { loadLayoutData } from './operations';

type SliceState = {
  loading: boolean,
  email: string,
  supportEmail: string,
  primaryNumber: string,
  secondaryNumber: string,
  jobsEmail: string,
  whatsApp: string,
  linkedin: string,
  twitter: string,
  xing: string,
  facebook: string,
  error: null | string,
  realAddress: string,
  realAddressMapsLink: string
};

const initialState: SliceState = { 
  loading: false,
  error: null,
  email: '',
  supportEmail: '',
  primaryNumber: '',
  secondaryNumber: '',
  jobsEmail: '',
  whatsApp: '',
  linkedin: '',
  twitter: '',
  xing: '',
  facebook: '',
  realAddress: '',
  realAddressMapsLink: ''
};

const contactInfoSlice = createSlice({
  name: 'contactInfo',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(loadLayoutData.pending, (state) => {
      state.loading = true;
      state.error = null;
    }).addCase(loadLayoutData.fulfilled, (state, { payload }) => {
      state.primaryNumber = payload.contactInfo.primaryPhoneNumber;
      state.email = payload.contactInfo.primaryEmail;
      state.supportEmail = payload.contactInfo.supportEmail;
      state.jobsEmail = payload.contactInfo.jobsEmail;
      state.whatsApp = payload.contactInfo.whatsApp;
      state.linkedin = payload.contactInfo.linkedin;
      state.twitter = payload.contactInfo.twitter;
      state.xing = payload.contactInfo.xing;
      state.facebook = payload.contactInfo.facebook;
      state.realAddress = payload.contactInfo.realAddress;
      state.realAddressMapsLink = payload.contactInfo.realAddressMapsLink;
      state.loading = false;
    }).addCase(loadLayoutData.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.loading = false;
    })
  },
});

export const { reducer, actions } = contactInfoSlice;