import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendRequestToAPI } from '../api/graphqlRequest';

type GqlResponseMenuItemType = {
  attributes: {
    slug: string;
    name: string;
  }
}

type LayoutDataType = {
  contactInfo: {
    primaryEmail: string
    supportEmail: string
    primaryPhoneNumber: string
    whatsApp: string
    linkedin: string
    twitter: string
    xing: string
    facebook: string
    jobsEmail: string;
    realAddress: string;
    realAddressMapsLink: string;
  };
  navigation: {
    solutions: GqlResponseMenuItemType[];
  }
};

export const loadLayoutData = createAsyncThunk<LayoutDataType, string, { rejectValue: string }>('contactInfo', async (req, { rejectWithValue }) => {
  const { data } = await sendRequestToAPI(
    req
  );
  if (!data) {
    rejectWithValue('Request Error');
  };
  return {
    contactInfo: data.data.ourContactInfo.data.attributes,
    navigation: {
      solutions: data.data.solutions.data
    }
  };
});