import axios, { RawAxiosRequestHeaders } from 'axios';

const { GATSBY_API_URL } = process.env;

export const sendRequestToAPI = (query: string, vars?: unknown, customHeaders?: RawAxiosRequestHeaders, customApiLink?: string) => {
  const variables = vars ? vars : {};
  return axios({
    url: customApiLink ? customApiLink : GATSBY_API_URL,
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...customHeaders
    },
    data: JSON.stringify({
      query,
      variables
    }),
  });
};
